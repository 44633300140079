@import "./reset";
@import "./fonts";

:root {
  --fontFamilyPrimary: "Gotham", sans-serif;

  --scrollbar-width: 15px;

  --boxShadow: 0 1px 5px 1px rgba(57, 57, 57, 0.24);

  --colorWhite: #f6f7f9;
  --colorBlack: #000;

  --colorPrimary: #4848ac;
  --colorPrimaryHover: #38388c;
  --colorPrimaryLight: #7475d4;
  --colorPrimaryDark: #303096;

  --colorSecondary: #fe782f;
  --colorSecondaryLight: #ff990c;

  --colorText: #505050;
  --colorTextGray: #707070;
  --colorTextError: #ff0000;
  --colorBackgroundPrimary: #f9f9f9;

  --colorSectionPrimary: #fff;
  --colorSectionSecondary: --colorBackgroundPrimary;
}

body {
  font-family: var(--fontFamilyPrimary);
  background-color: var(--colorBackgroundPrimary);
  color: var(--colorText);
}
