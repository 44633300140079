@use "src/styles/utilities/_variables" as vr;

.wrapper {
  margin-top: vr.$spacingSize40;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: vr.$spacingSize50;
}

.table {
  font-size: 1.2rem;
  border-collapse: collapse;
  table-layout: fixed;

  tr, th, td {
    border: 1px solid var(--colorTextGray);
    padding: 0.5rem;
    font-size: 11px;
    vertical-align: middle;
  }
}

.textCentered {
  text-align: center;
}

.optionWrapper {
  display: flex;
  align-items: center;
  gap: vr.$spacingSize20;
}