@use 'src/styles/utilities/_variables' as vr;

.overlay {
  height: 100vh;
  width: 100vw;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  left: 0;
  top: 0;
  z-index: 100;
  cursor: pointer;
}

.hidden {
  display: none;
}

.modal {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--colorWhite);
  border-radius: 10px;
  max-width: 985px;
  max-height: 90vh;
  cursor: default;
  display: flex;
  flex-direction: column;
}

.content {
  position: relative;
  padding: vr.$spacingSize60 vr.$spacingSize20 vr.$spacingSize60;
  display: flex;
  flex-direction: column;
  gap: vr.$spacingSize50;
  align-items: center;
  min-height: 250px;
}

.closeButton {
  position: absolute;
  right: 18px;
  top: 18px;
  height: 24px;
  width: 24px;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  z-index: 2;
  color: var(--colorBlack);
}
