//=========================== Spacing ===========================
$spacingBasicUnit: 8px;

$spacingSize10: $spacingBasicUnit / 2; // 4px
$spacingSize20: $spacingBasicUnit; // 8px
$spacingSize30: $spacingBasicUnit * 1.5; // 12px
$spacingSize40: $spacingBasicUnit * 2; // 16px
$spacingSize50: $spacingBasicUnit * 3; // 24px
$spacingSize60: $spacingBasicUnit * 4; // 32px
$spacingSize70: $spacingBasicUnit * 6; // 48px
$spacingSize80: $spacingBasicUnit * 8; // 64px
$spacingSize90: $spacingBasicUnit * 12; // 96px
$spacingSize100: $spacingBasicUnit * 20; // 160px
//=========================== End Spacing ===========================

// media breakpoints
$breakpointMobile: 640px;
$breakpointDesktop: 1000px;
