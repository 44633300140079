@use "../../styles/utilities/variables" as vr;

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: vr.$spacingSize70 0;
}

.table {
  font-size: 1.2rem;
  border-collapse: collapse;
  table-layout: fixed;

  tr, th, td {
    border: 1px solid var(--colorTextGray);
    padding: 0.5rem;
    font-size: 11px;
    vertical-align: middle;
  }
}

.title {
  margin-bottom: vr.$spacingSize40;
}

.textCentered {
  text-align: center;
}

.btn {
  margin: vr.$spacingSize50 0 vr.$spacingSize80;
}